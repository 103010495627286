<template>
    <div>
        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl" />
            </div>
        </div>
        <div v-else class="box">
            <TabRank :accompagnements="ranks" @loadAsyncData="loadAsyncData" />
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '../../components/UI/Toaster'

    export default {
        title: 'Rank',
        name: 'Rank',
        components: {
            Toaster
        },
        data: function () {
            return {
                preloading: true,
                accompagnements: [],
                ranks: null
            }
        },
        watch: {},
        created() {
            this.preloading = true
            this.axios.get('/admin/rank').then(response => {
                this.parseResult(response.data.accompagnements)
            })

            const data = document.querySelector('#vue-data')
            var accompagnements = JSON.parse(data.accompagnements.data)

            var tabAccompagnementId = []
            var tabKeywordsId = []

            for (var i = 0; i < accompagnements.length; i++) {
                var accompagnement = accompagnements[i]

                var firstDate = null
                var dateFound = false
                var keywords = []
                for (var j = 0; j < accompagnement.listKW.length; j++) {
                    var keyword = accompagnement.listKW[j]
                    keyword.isHeader = false

                    if (tabKeywordsId.includes(keyword.id) === false) {
                        keywords.push(keyword)
                        tabKeywordsId.push(keyword.id)
                    }

                    if (!dateFound && keyword.check_date) {
                        dateFound = true

                        firstDate = keyword.check_date
                    }
                }

                if (tabAccompagnementId.includes(accompagnement.id) === false) {
                    var row = {
                        isHeader: true,
                        objectif_kw: accompagnement.url,
                        id: accompagnement.id,
                        date: firstDate
                    }

                    this.accompagnements.push(row)
                    tabAccompagnementId.push(accompagnement.id)
                }

                this.accompagnements = this.accompagnements.concat(keywords)
            }
        },
        mounted: function () {},
        methods: {
            parseResult: function (accompagnements) {
                var data = accompagnements.data
                var ranks = []
                var lastHeader = ''

                for (var i = 0; i < data.length; i++) {
                    if (data[i] && data[i].accompagnement_url) {
                        if (lastHeader != data[i].url) {
                            ranks.push({
                                id: data[i].accompagnement_id,
                                isHeader: true,
                                url: data[i].url,
                                queryId: data[i].query_id,
                                monitorankId: data[i].monitorank_id_project
                            })

                            lastHeader = data[i].url
                        }

                        data[i].isHeader = false

                        ranks.push(data[i])
                    }
                }

                this.ranks = accompagnements
                this.ranks.data = ranks
                this.preloading = false
            },
            loadAsyncData(data) {
                var field = data.sortField

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                this.axios
                    .get(data.path, {
                        params
                    })
                    .then(response => {
                        this.parseResult(response.data.accompagnements)
                    })
                    .finally(() => {
                        data.callback()
                    })
            }
        }
    }
</script>

<style scoped></style>
